<template>
  <node-view-wrapper :id="idSelect" class="select_componet_create">
    <span class="select_value_create" tabindex="0" @click="editar">cadastre as opções</span>
    <v-dialog v-model="dialog" persistent max-width="800">

      <v-card>
        <v-card-title class="mb-3">Seleção Pré-definida
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="addOpcao">Adicionar Opção</v-btn>
        </v-card-title>
        <v-card-text class="context_campos">
          <div v-for="(item, idx) in opcoesTmp" :key="`op${idx}`">
            <v-textarea @click:append-outer="removerOpcao(idx)" append-outer-icon="delete" filled rounded rows="1"
              auto-grow no-resize :label="`Opção ${idx}`" v-model="item.value"></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="cancelar">Cancelar</v-btn>
          <v-btn color="primary" @click="salvar">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";

export default {
  data() {
    return {
      idSelect: "select_" + Math.random().toString(36).substring(2, 9),
      currentLI: -1,
      dialog: false,
      opcoesTmp: []
    };
  },
  components: {
    NodeViewWrapper
  },
  methods: {
    setValue(li) {
      if (this.currentLI !== -1) {
        this.select.querySelectorAll('li').forEach(function (lit) {
          lit.classList.remove('selected');
        });
      }

      this.currentLI = parseInt(li.getAttribute('pos'));
      li.classList.add("selected");
      this.selecionado = li.innerHTML;
    },
    
    editar() {// event
      // this.$emit('editar', this.node.attrs);
      // console.log("editar", event, this.node.attrs);

      this.opcoesTmp = this.node.attrs.opcoes.split('|').map(item => ({ "value": item.trim() }));
      this.dialog = true
    },
    salvar() {
      this.dialog = false
      console.log(this.opcoesTmp, this.opcoesTmp.map(i => i.value).join('|'));
      this.node.attrs.opcoes = this.opcoesTmp.map(i => i.value).join('|');
      this.updateAttributes({ opcoes: this.node.attrs.opcoes });
    },
    addOpcao() {
      this.opcoesTmp.push({ "value": "" });
    },
    removerOpcao(idx) {
      console.log(idx);
      this.opcoesTmp.splice(idx, 1);
    },
    cancelar() {
      this.dialog = false
    }
  },
  mounted() {
    const self = this;
    setTimeout(() => {
      self.select = document.querySelector(`#${self.idSelect}.select_componet_create`);
      self.input = self.select.querySelector('.select_value_create');

      self.input.addEventListener('click', () => {
        self.input.focus();
      });

      self.input.addEventListener('focus', self.show);

      if (this.node.attrs.opcoes == "") {
        this.opcoesTmp = [{ "value": "" }];
        this.dialog = true;
      }
    }, 200);
  },

  props: nodeViewProps
};
</script>

<style lang="scss">
.theme--dark{}

.select_componet_create {
  display: inline-block;
  position: relative;
}

.select_value_create {
  min-width: 50px;
  background-color: #ffee58;
  color: #000000;
  border: none;
  padding: 2px 5px;
  border-radius: 5px;
}

.select_value_create:focus {
  outline: 2px solid #fdd835;
  cursor: pointer;
}

.select_value_create:hover {
  outline: 2px solid #fdd835;
  cursor: pointer;
}

.opcoes {
  position: absolute;
  background-color: #ffee58;
  border: 1px solid #fdd835;
  color: #000000;
  border-radius: 5px;
  padding: 0px !important;
  margin-bottom: 10px;
  display: none;
  margin-top: 2px;
  z-index: 1000;
  min-width: 400px;  
}

.opcoes li {
  list-style: none;
  padding: 5px;
  border-bottom: 1px solid #fdd835;
}

.opcoes li:hover {
  background-color: #fdd835;
  cursor: pointer;
}

.opcoes li.selected {
  background-color: #fdd835;
  cursor: pointer;
}

.show {
  display: block !important;
}

.context_campos {
  overflow: auto;
  height: 400px;
}
</style>
